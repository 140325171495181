<template>
  <v-card class="mx-auto nav-paging nav-container" color="grey-lighten-3" max-width="448">
    <v-layout>
      <v-app-bar
        color="teal-darken-4"
        :image="require('@/assets/library.jpeg')"
      >
        <div class="nav-logo">
          <router-link to="/home">
            <img :src="require('@/assets/logo-nav.png')" alt="Bookly nav" width="100">
          </router-link>
        </div>

        <v-spacer></v-spacer>

        <router-link to="/announces">
          <v-btn icon class="text-white">
            <v-icon>mdi-book-edit</v-icon>
          </v-btn>
        </router-link>

        <router-link to="/profile">
          <v-btn icon class="text-white">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </router-link>

        <router-link to="/" @click="logout">
          <v-btn icon class="text-white">
            <v-icon>mdi-exit-to-app</v-icon>
          </v-btn>
        </router-link>
      </v-app-bar>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  methods: {
    logout(event) {
      event.preventDefault();

      this.$apiLogout(
        () => {
          // Logout avvenuto con successo, reindirizza alla home
          this.$router.push('/');
        },
        (error) => {
          // Gestione dell'errore, puoi mostrare un messaggio di errore
          console.error('Errore durante il logout:', error);
        }
      );
    }
  }
}
</script>

<style scoped>
.nav-logo {
  padding: 35px 0px 20px 20px;
}

.nav-paging {
  margin-bottom: 125px;
}

.nav-container {
  z-index: 1004;
}
</style>