email<template>
    <div class="login-logo">
        <router-link to="/">
        <img :src="require('@/assets/logo-home.png')" alt="Bookly logo" width="200">
        </router-link>
    </div>
    <div>
        <v-card
        class="mx-auto pa-12 pb-8"
        elevation="8"
        max-width="448"
        rounded="lg"
        >
        <div class="text-subtitle-1 text-medium-emphasis d-flex">{{$t('email')}}</div>

        <v-text-field
            v-model="mail"
            density="compact"
            placeholder="Email"
            prepend-inner-icon="mdi-email-outline"
            variant="outlined"
        ></v-text-field>

        <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
            {{$t('password')}}
            <a
            class="text-caption text-decoration-none primary-color"
            href="#"
            rel="noopener noreferrer"
            target="_blank"
            >
            {{$t('forgot_password')}}</a>
        </div>

        <v-text-field
            v-model="pass"
            :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
            :type="visible ? 'text' : 'password'"
            density="compact"
            placeholder="Password"
            prepend-inner-icon="mdi-lock-outline"
            variant="outlined"
            @click:append-inner="visible = !visible"
        ></v-text-field>

        <div class="mb-4 mt-4">
            <btn class="bg-primary-color login-btn" @click="login">
                {{$t('login')}}
            </btn>
        </div>

        <v-card-text class="text-center">
            <router-link class="text-decoration-none" to="/signin">
            <a
                class="text-decoration-none primary-color"
                href="#"
                rel="noopener noreferrer"
                target="_blank"
            >
                {{$t('signin')}} <v-icon icon="mdi-chevron-right"></v-icon>
            </a>
            </router-link>
        </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            mail: '',
            pass: '',
            visible: false,
        }
    },
    methods: {
        async login() {
            if (this.mail === '' || this.pass === '') {
                alert('Per favore inserisci email e password')
                return
            }

            const formData = new FormData()
            formData.append('mail', this.mail)
            formData.append('pass', this.pass)

            this.$apiLogin(formData, (data) => {
                console.log(data)
                if (data.data && data.data.success) {
                    this.$router.push('/home')
                }
            }, (data) => {
                console.log(data)
            })
        },
    },
};
</script>

<style scoped>

</style>