export default {
    install: (app) => {
        // Get enviroment
        app.config.globalProperties.$getEnviroment = function() {
            if (window.location.href.includes("localhost") || window.location.href.includes("192")) {
                return 0 // LOCALE
            } else if (window.location.href.includes("sandbox")) {
                return 1 // SANDBOX
            }
            return 2 // PRODUCTION
        }

        // Get domain name
        app.config.globalProperties.$domainName = function() {
            return window.location.protocol + "//" + (app.config.globalProperties.$getEnviroment() == 0 ? window.location.hostname + ":80" : window.location.host)
        }

        // Get API
        app.config.globalProperties.$get = function(url, cb, cbe, tmo) {
            return this.axios.create({withCredentials: true, timeout: tmo}).get(app.config.globalProperties.$domainName() + "/api/" + url).then(response => cb(response)).catch((err) => cbe(err))
        }

        // Post API
        app.config.globalProperties.$post = function(url, form, cb, cbe, tmo) {
            console.log(app.config.globalProperties.$domainName())
            return this.axios.create({withCredentials: true, timeout: tmo}).post(app.config.globalProperties.$domainName() + "/api/" + url, form).then(response => cb(response)).catch((err) => cbe(err))
        }

        // Login API
        app.config.globalProperties.$apiLogin = function(form, cb, cbe) {
            app.config.globalProperties.$post("login.php", form, cb, cbe, 60000)
        }

        // Logout API
        app.config.globalProperties.$apiLogout = function(cb, cbe) {
            app.config.globalProperties.$get("logout.php", cb, cbe, 60000)
        }
    }
}